<template>
  <div class="c-task-config-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      title="选择人员"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section>
        <el-form ref="allUserItem" label-width="100px" >
          <section class="m-grid-search">
            <znl-input
              ref="departInput"
              border
              width="150px"
              type="text"
              clearable
              :select-options="departmentList"
              placeholder="所属部门"
              form-type="select"
              v-model="sendData.DeptID"
              class="m-margin--right-10"
            >
            </znl-input>
            <znl-input
            ref="userInput"
              border
              width="150px"
              placeholder="人员名称"
              clearable
              class="m-margin--right-10"
              v-model="sendData.UserName"
            >
            </znl-input>

            <el-button class="m-grid-btn" type="primary" @click="onSearch()"
              >查询</el-button
            >
          </section>
          <i style="font-size: 10px;font-weight: 200;">(可双击选中)</i>
          <div class="m-grid-list">
            <grid
              ref="comuser"
              :columns="comUserColumns"
              :datas="userItemSource"
              :selection="true"
              domLayout="autoHeight"
              headerHeight="35"
              type="base"
              @onCellDoubleClicked="onCellDoubleClicked"
            >
            </grid>
          </div>

          <el-form-item style="text-align: right; margin-top: 20px">
            <el-button type="primary" @click="onSave">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { GetDepartmentList } from "@/api/config";
import { allUserDempercolumns } from "./config";
export default {
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      comUserColumns: allUserDempercolumns,
      userItemSource: [],
      sendData:{
        DeptID:0,
        DeptName:'',
        UserName:''
      },
      departmentList:[]
    };
  },
  methods: {
    async onOpen(selectData) {

      let sendData={
        DeptID:0,
        DeptName:'',
        UserName:''
      }
      // const data = this.$store.state.common.SystemDepart;
      const data = await GetDepartmentList(sendData);
      this.userItemSource = data;
      this.departmentList=this.setOption(data)
      this.$nextTick(() => {
        this.setSelected(selectData);
      });

      this.visible = true;


      this.$nextTick(() => {
        this.$refs.departInput.clear();
        this.$refs.userInput.clear();
      })
    },
    setSelected(selectIds) {
      this.$refs.comuser.forEachNode((node) => {
        const { data } = node;
        if (selectIds.find((value) => value.UserID === data.UserID)) {
          if (node.selected) {
            return node.setSelected(false);
          }
          return node.setSelected(true);
        }
        // else {
        //   return node.setSelected(false);
        // }
      });
    },
    // 双击选中
    onCellDoubleClicked(row) {
      const { data } = row;
      this.setSelected([data]);
    },
    onSave() {
      var selectList = this.$refs.comuser.getSelectedRows();
      this.$emit("onEditUserConfig", selectList);
      this.visible = false;
    },
    setOption(data){
        const list = this.$store.state.common.SystemDepart
        let option = list.map((item) => {
          return{
            key:item.DeptID,
            value:item.DeptName
          }
        })
        return option;
    },
    async onSearch(){
      const data = await GetDepartmentList(this.sendData);
      this.userItemSource = data;
    },
    handleDrag() {},

  },
  created() {

  },
};
</script>

<style lang="scss">
.c-task-config-view-dialog {
  .el-dialog {
    max-width: 800px !important;
    min-width: 600px !important;
    max-height: 750px !important;
    min-height: 650px !important;
    overflow: auto;
  }
  .grid-container{
     height: 500px;
     overflow: auto;
  }
}
</style>
