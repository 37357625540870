<template>
  <div class="c-task-config-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      title="选择规则"
      :visible.sync="visible"
      @dragDialog="handleDrag"
    >
      <section>
        <el-form ref="allRuleItem" label-width="100px">
          <i style="font-size: 10px; font-weight: 200">(可双击选中)</i>
          <div class="m-grid-list">
            <grid
              ref="comrules"
              :columns="comRuleColumns"
              :datas="comRuleitemSource"
              :selection="true"
              domLayout="autoHeight"
              headerHeight="35"
              type="base"
              @onCellDoubleClicked="onCellDoubleClicked"
            >
            </grid>
          </div>

          <el-form-item style="text-align: right; margin-top: 20px">
            <el-button type="primary" @click="onSave">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog"; // base on element-ui
import { getAllCommonRule } from "@/api/config";
import { ruleColumns } from './config'
export default {
  directives: {
    elDragDialog,
  },
  data() {
    return {
      visible: false,
      comRuleColumns: ruleColumns.map(item => Object.assign({}, item)).filter(item => item.field != 'operation'),
      comRuleitemSource: [],
    };
  },
  methods: {
    async onOpen(selectData) {
      const data = await getAllCommonRule();
      this.comRuleitemSource = data;
      this.visible = true;
      this.$nextTick(() => {
        this.setSelected(selectData);
      });
    },
    setSelected(selectIds) {
      this.$refs.comrules.forEachNode((node) => {
        const { data } = node;
        if (selectIds.find((value) => value.RuleID === data.RuleID)) {
          if (node.selected) {
            return node.setSelected(false);
          }
          return node.setSelected(true);
        }
      });
    },
    // 选中
    onCellDoubleClicked(row) {
      const { data } = row;
      this.setSelected([data]);
    },
    onSave() {
      let selectList = this.$refs.comrules.getSelectedRows();
      //这段代码不要删，需要时可恢复！
      //判断--规则类型不能一样，适用客户类型需要全部一样
      // let ruleMap = selectList.map((item) => item.RuleType);
      // let ruleArr = [...new Set(ruleMap)];
      // if (ruleArr.length !== selectList.length) {
      //   this.$message.warning("选择的规则类型不能一样，请重新选择");
      //   return;
      // }
      // let typeMap = selectList.map((item) => item.CustomerType);
      // let typeArr = [...new Set(typeMap)];
      // if (typeArr.length !== 1) {
      //   this.$message.warning("选择的适用客户类型需要全部一样，请重新选择");
      //   return;
      // }

      this.$emit("onEditRuleConfig", selectList);
      this.visible = false;
    },
    handleDrag() { },
  },
};
</script>

<style lang="scss">
.c-task-config-view-dialog {
  .el-dialog {
    max-width: 800px !important;
    min-width: 600px !important;
    max-height: 750px !important;
    min-height: 650px !important;
    overflow: auto;
  }
  .grid-container{
     height: 500px;
     overflow: auto;
  }
}
</style>
