<template>
  <div class="m-grid p-common-config">
    <section style="line-height: 45px; margin-bottom: 10px">
      <div>
        <znl-input
          :border="true"
          :clearable="true"
          v-model="editItem.Name"
          :height="30"
          placeholder="请输入公海名称"
          title="公海名称:"
          title-width="60px"
          type="text"
          width="400px"
        >
        </znl-input>
      </div>
      <!-- <div>
        <znl-input
          :border="true"
          :clearable="true"
          :height="30"
          form-type="select"
          v-model="editItem.UpStream"
          :select-options="allUpStreamList"
          ref="UpStreams"
          placeholder="请选择下游公海"
          title="上游公海:"
          title-width="60px"
          width="400px"
        >
        </znl-input>
      </div>
      <div>
        <znl-input
          :border="true"
          :clearable="true"
          :height="30"
          form-type="select"
          v-model="editItem.DownStream"
          :select-options="allDownStreamList"
          placeholder="请选择下游公海"
          title="下游公海:"
          title-width="60px"
          width="400px"
        >
        </znl-input>
      </div> -->
      <znl-input
        :border="true"
        :clearable="true"
        v-model="editItem.Description"
        :height="30"
        placeholder="请输入公海说明"
        title="公海说明:"
        title-width="60px"
        type="text"
        width="400px"
      >
      </znl-input>
    </section>
    <section style="margin-bottom: 20px">
      <p style="margin-bottom: 10px">
        <span style="font-size: 12px; font-weight: 700"
          >公海规则：<i
            style="cursor: pointer; color: blue"
            @click="getAllRule()"
            >选择规则</i
          ></span
        >
      </p>
      <div class="m-grid-list">
        <grid
          ref="list"
          :columns="columns"
          :datas="itemSource"
          :selection="false"
          domLayout="autoHeight"
          headerHeight="35"
          type="base"
        >
        </grid>
      </div>
    </section>

    <section style="margin-bottom: 20px">
      <p style="margin-bottom: 10px">
        <span style="font-size: 12px; font-weight: 700"
          >公海人员及捞取数量：<i
            style="cursor: pointer; color: blue"
            @click="getAllUserDepar()"
            >选择人员</i
          ></span
        >
      </p>
      <div class="m-grid-list">
        <grid
          ref="userList"
          :columns="userColumns"
          :datas="userSource"
          :selection="false"
          domLayout="autoHeight"
          headerHeight="35"
          type="edit"
        >
        </grid>
      </div>
    </section>
    <section>
      <div style="font-size: 12px; font-weight: 700; line-height: 40px">
        <ul style="list-style: none">
          <li>
            <span>领取限制:</span>
            <span style="margin-left: 54px"
              >该公海同一员工
              <znl-input
                :border="true"
                :height="26"
                v-model="editItem.PickLimit"
                placeholder=""
                type="number"
                width="40px"
              >
              </znl-input
              >天内不能连续领取同一个客户</span
            >
          </li>
          <li>
            <span>百分比分配规则:</span>
            <span style="margin-left: 16px">
              同一销售员每完成任务
              <znl-input
                :border="true"
                :height="26"
                v-model="editItem.CompletedPercent"
                placeholder=""
                type="number"
                width="40px"
              ></znl-input
              >% &nbsp;&nbsp;分配
              <znl-input
                :border="true"
                :height="26"
                v-model="editItem.PercentAssignCustomerQty"
                placeholder=""
                type="number"
                width="40px"
              ></znl-input
              >位客户到其名下
              <el-checkbox
                style="font-size: 12px; margin-left: 50px; font-weight: 700"
                v-model="editItem.EnablePercentAssign"
              >
                是否启用该分配规则
              </el-checkbox>
            </span>
          </li>
          <li>
            <span>自动分配规则:</span>
            <span style="margin-left: 27px">
              每
              <znl-input
                :border="true"
                :height="26"
                v-model="editItem.AutoAssignTriggerType"
                :select-options="autoDataTypeMap"
                placeholder=""
                form-type="select"
                width="50px"
              ></znl-input>
              <znl-input
                style="margin-left: 5px"
                :border="true"
                :height="26"
                v-model="editItem.AutoAssignMode"
                :select-options="autoAssignMap"
                placeholder=""
                form-type="select"
                width="100px"
              ></znl-input>
              分配
              <znl-input
                :border="true"
                :height="26"
                v-model="editItem.AutoAssignNumber"
                placeholder=""
                type="number"
                width="40px"
              ></znl-input>
              位客户到同一销售员
              <el-checkbox
                style="font-size: 12px; margin-left: 28px; font-weight: 700"
                v-model="editItem.EnableAutoAssign"
                >是否启用该分配规则</el-checkbox
              >
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section style="margin-top: 20px">
      <el-button type="primary" @click="onSave" >保存</el-button>
      <!-- <el-button @click="visible = false">取消</el-button> -->
    </section>
    <common-rule-list
      ref="commonRule"
      @onEditRuleConfig="onEditRuleConfig"
    ></common-rule-list>
    <common-user-list
      ref="commonUser"
      @onEditUserConfig="onEditUserConfig"
    ></common-user-list>
  </div>
</template>

<script>
import { ruleColumns, userColumns } from "./config";
import {
  getCommonConfig,
  getAllCommonConfig,
  commonConfigAdd,
  commonConfigEdit,
} from "@/api/config";
import { autoDataTypeMap, autoAssignMap } from "./dataMap";
import CommonRuleList from "./CommonRuleList.vue";
import CommonUserList from "./CommonUserList";
export default {
  name: "CommonConfigEdit",
  components: {
    CommonRuleList,
    CommonUserList,
  },
  data() {
    return {
      // visibleLine: '', //隐藏
      columns: ruleColumns,
      userColumns: userColumns,
      itemSource: [],
      userSource: [],
      isEditMode: false,
      editItem: {
        SeaId: 0,
        Name: "",
        UpStream: 0,
        DownStream: 0,
        Description: "",
        PickLimit: 0,
        CompletedPercent: 0,
        PercentAssignCustomerQty: 0,
        AutoAssignTriggerType: 0,
        AutoAssignMode: 0,
        AutoAssignNumber: 0,
        EnablePercentAssign: false,
        EnableAutoAssign: false,
        RuleList: [],
        UserList: [],
      },
      autoDataTypeMap: autoDataTypeMap,
      autoAssignMap: autoAssignMap,
      allUpStreamList: [],
      allDownStreamList: [],

      initId: 0
    };
  },
  watch: {
    '$route'(route) {
      const { id } = route.query;
      if (id === this.initId) return;
      const { name } = route;
      console.log(name)
      this.onInit();
    },
  },
  methods: {
    async onInit() {
      const { name, query } = this.$route;
      const keys = ["CommonConfigAdd", "CommonConfigEdit"];
      if (!keys.includes(name)) {
        return false;
      }
      this.isEditMode = name === "CommonConfigEdit";
      let allSeaPool = await getAllCommonConfig();
      if (this.isEditMode) {
        await this.getData();
        allSeaPool = allSeaPool.filter(
          (item) => Number(item.SeaID) !== Number(query.id)
        );
      }
      if (allSeaPool) {
        this.allUpStreamList = this.setOption(allSeaPool, "upStream");
        this.allDownStreamList = this.setOption(allSeaPool, "downStream");
      }

      this.initId = query.id
    },
    //获取数据
    async getData() {
      let id = this.$route.query.id;
      const data = await getCommonConfig({ SeaId: id }); // 请求接口
      console.log(data)
      // if(data.IsPreset)
      // {
      //     this.visibleLine='none'
      // }
      this.itemSource = data.RuleList;
      this.userSource = data.UserList;
      Object.keys(data).forEach((key) => (this.editItem[key] = data[key]));
    },
    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = ruleColumns.find(
        (item) => item.field === "operation"
      );
      const UserColumn = userColumns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      if (UserColumn) {
        UserColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(ruleColumns);
      this.$refs.userList.changeColumns(userColumns);
    },
    setButtons() {
      let buttons = [];
      let self = this;
      const deleteBtn = {
        text: "删除",
        click(params) {
          self.onOpenDelete(params.data);
        },
      };
      buttons.push(deleteBtn);
      return buttons;
    },
    //两个表格删除事件
    onOpenDelete(data) {
      if (data.RuleID) {
        //删除规则
        let lines = this.itemSource.filter(
          (item) => data.RuleID !== item.RuleID
        );
        this.editItem.RuleList = lines;
        this.itemSource = lines;
        this.$nextTick(() => {
          this.$refs.list.updateRowData(this.itemSource);
        });
      } else {
        //删除人员
        let lines = this.userSource.filter(
          (item) => data.UserID !== item.UserID
        );
        this.userSource = lines;
        this.editItem.UserList = lines;
        this.$nextTick(() => {
          this.$refs.userList.updateRowData(this.userSource);
        });
      }
    },
    //返回公海下拉列表数据
    setOption(data, key) {
      if (!data) return [];
      let id = this.$route.query.id;
      let option = data.map((item) => {
        if (key === "upStream") {
          //console.log(this.editItem.UpStream,item.SeaID)
          //&& this.editItem.UpStream !== item.SeaID
          //console.log(parseInt(item.DownStream) > 0 && this.editItem.UpStream !== item.SeaID)
          return {
            key: item.SeaID,
            value: item.Name,
            disabled: parseInt(item.DownStream) > 0 && this.editItem.UpStream !== item.SeaID,
          };
        } else {
          return {
            key: item.SeaID,
            value: item.Name,
            disabled: (parseInt(item.UpStream) > 0 || item.IsPreset) && this.editItem.DownStream !== item.SeaID ,
          };
        }
      });
      return option;
    },
    //打开选择规则表格
    getAllRule() {
      this.$refs.commonRule.onOpen(this.itemSource);
    },
    //打开选择人员表格
    getAllUserDepar() {
      this.$refs.commonUser.onOpen(this.userSource);
    },
    //规则表格点击保存之后的事件
    onEditRuleConfig(data) {
      this.editItem.RuleList = data;
      this.itemSource = data;
      this.$refs.list.setRowData(data);
    },
    //人员表格点击确定之后的事件
    onEditUserConfig(data) {
      data.forEach((item) => {
        var getFindData = this.userSource.find((x) => x.UserID === item.UserID);
        if (getFindData) {
          item.LimitQty = getFindData.LimitQty;
        }
      });
      this.editItem.UserList = data;
      this.userSource = data;
      this.$refs.userList.setRowData(data);
    },
    //最后的保存按钮
    async onSave() {
      let dataConfig = this.dataCheck();
      if (dataConfig === false) {
        return false;
      }

      const { isEditMode } = this;
      const params = this.editItem;
      console.log(params)
      let message = "修改成功";
      if (isEditMode) {
        let editdataId = await commonConfigEdit(params);
        //console.log(editdataId);
      } else {
        let adddataId = await commonConfigAdd(params);
        //console.log(adddataId);
        message = "新增成功";
      }
      this.sendReloadMsg();
      this.$router.push({
        name: 'configCommonConfig',
      })
      this.$message.success(message);
      await this.$store.dispatch('tagsView/delView', this.$route)
    },
    dataCheck() {
      if (this.isEmptyStr(this.editItem.Name)) {
        this.$message({ message: "请输入公海名称", type: "warning" });
        return false;
      }
      if (this.isEmptyStr(this.editItem.Description)) {
        this.$message({ message: "请输入公海说明", type: "warning" });
        return false;
      }
      if (this.editItem.RuleList.length === 0) {
        this.$message({ message: "请选择规则", type: "warning" });
        return false;
      }
      if (this.editItem.UserList.length === 0) {
        this.$message({ message: "请选择人员", type: "warning" });
        return false;
      }
      if (this.editItem.DownStream > 0) {
        if (this.editItem.UpStream === this.editItem.DownStream) {
          this.$message({
            message: "上游公海和下游公海不能一样",
            type: "warning",
          });
          return false;
        }
      }
      const regExp = new RegExp(/^[0-9]+$/);
      var isNumber = this.editItem.UserList.find(
        (item) => !regExp.test(item.LimitQty)
      );
      if (isNumber) {
        this.$message({ message: "捞取数量限制，请输入数字", type: "warning" });
        return false;
      }
      return true;
    },
    isEmptyStr(value) {
      return value === undefined || value === null || value === "";
    },
    // 向公海配置列表页面发送刷新指令
    sendReloadMsg(){
      this.$EventBus.$emit('sendReloadMsg',"刷新公海配置列表页面")
    }
  },
  created() {
    this.onInit();
  },
  mounted() {
    this.initColumns();
  }
};
</script>

<style lang='scss' scoped >
.p-common-config {
  box-sizing: border-box;
  padding: 50px;
}
.el-checkbox__label {
  font-size: 12px;
}
</style>

